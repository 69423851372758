<template>
  <div style="height: 100%">
    <nesi-user-common>
      <template #content>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 450px;
            flex-direction: column;
          "
        >
          <el-form
            ref="vform"
            :model="form"
            class="demo-form-inline"
            :rules="rules"
            style="padding-top: 20px"
          >
            <el-form-item label="身份证号码" label-width="150px" prop="cardNo">
              <el-input
                v-model="form.cardNo"
                clearable
                placeholder="请输入身份证号码"
                maxlength="128"
                style="width: 300px"
                disabled
              />
            </el-form-item>
            <el-form-item label="新密码" label-width="150px" prop="newPassword">
              <el-input
                v-model="form.newPassword"
                placeholder="请输入新密码"
                type="password"
                style="width: 300px"
                show-password="true"
              />
            </el-form-item>
            <el-form-item
              label="确认密码"
              label-width="150px"
              prop="confirmPassword"
            >
              <el-input
                v-model="form.confirmPassword"
                placeholder="请输入确认密码"
                type="password"
                style="width: 300px"
                show-password="true"
              />
            </el-form-item>
            <el-form-item label="注册手机号" label-width="150px" prop="mobile">
              <el-input
                v-model="form.mobile"
                clearable
                placeholder="请输入注册手机号"
                maxlength="64"
                style="width: 300px"
                disabled
              />
            </el-form-item>
            <el-form-item label="图形验证码" label-width="150px">
              <div style="display: flex">
                <el-input
                  v-model="form.captcha"
                  clearable
                  placeholder="请输入图形验证码"
                  maxlength="64"
                  style="width: 170px"
                />
                <el-image
                  :src="imageUrl"
                  style="
                    display: flex;
                    height: 38px;
                    cursor: pointer;
                    margin-left: 20px;
                  "
                  @click="getIamge"
                />
              </div>
            </el-form-item>
            <el-form-item label="短信验证" label-width="150px" prop="code">
              <el-input
                v-model="form.code"
                clearable
                placeholder="请输入短信验证码"
                maxlength="64"
                style="width: 170px"
              />
              <el-button
                type="primary"
                @click="sendUserCode"
                style="margin-left: 20px; width: 110px"
                >手机验证码</el-button
              >
            </el-form-item>
          </el-form>
          <div class="btn-wrapper">
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="submitForm" :disabled="sDisable"
              >提交</el-button
            >
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { checkPasswordRule } from '@/utils/checkPassword'
import { getSmsApi } from '@/api/user'
import { encryptByPassword } from '@/utils/util'
import { changePwd } from '@/api/sys'
import { randomImage } from '@/api/login'

defineComponent({
  NesiUserCommon
})
const store = useStore()
const router = useRouter()

const imageUrl = ref('')
const checkKey = ref('')

// 密码校验规则
const validatePassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    const result = checkPasswordRule(value)
    if (result === '校验通过') {
      callback()
    } else {
      callback(new Error(result))
    }
  }
}
onMounted(() => {
  getIamge()
})
const getIamge = async () => {
  form.captcha = ''
  const key = new Date().getTime()
  const resp = await randomImage({ key })
  checkKey.value = key
  imageUrl.value = resp
}

// 密码校验规则
const validateSamePassword = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入确认密码'))
  } else if (!form.newPassword) {
    callback(new Error('请输入密码'))
  } else {
    if (value != form.newPassword) {
      callback(new Error('请确认新密码和确认密码是否一致'))
    } else {
      callback()
    }
  }
}

// 发送短信
const sendUserCode = async () => {
  if (!form.captcha) {
    ElMessage.error('请输入图形验证码')
    return
  }
  const params = {
    phone: form.mobile,
    captcha: form.captcha,
    checkKey: checkKey.value
  }
  const result = await getSmsApi(params)
  if (result.code != 200) {
    const msg = result.message.replace('验证码', '图形验证码')
    ElMessage.error(msg)
    return
  }
  ElMessage({ type: 'success', message: '发送成功，请查看手机短信验证码' })
}

const user = computed(() => store.getters.userInfo).value
const vform = ref()
const rules = ref({
  cardNo: [{ required: true, message: '身份证号码不能为空', trigger: 'blur' }],
  mobile: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
  newPassword: [
    { required: true, message: '新密码不能为空', trigger: 'blur' },
    { validator: validatePassword, trigger: 'blur' }
  ],
  confirmPassword: [
    { required: true, message: '确认密码不能为空', trigger: 'blur' },
    { validator: validatePassword, trigger: 'blur' },
    { validator: validateSamePassword, trigger: 'blur' }
  ],
  code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
})

const form = reactive({
  cardNo: user.cardNo,
  mobile: user.phone,
  password: '',
  newPassword: '',
  confirmPassword: '',
  code: '',
  captcha: ''
})
const reset = () => {
  form.password = ''
  form.newPassword = ''
  form.confirmPassword = ''
  form.code = ''
}

const submitForm = () => {
  vform.value.validate(async (valid) => {
    if (valid) {
      const data = {
        cardNo: form.cardNo,
        code: form.code,
        mobile: form.mobile,
        password: encryptByPassword(form.mobile, form.newPassword)
      }
      await changePwd(data)
      ElMessage.success('密码重置成功')
      await store.dispatch('user/getUserInfo')
      setTimeout(() => {
        router.push({ path: '/' })
      }, 300)
    }
  })
}
</script>
<style lang="scss" scoped>
.btn-wrapper {
  margin-top: 30px 0;
  padding: 0 10px;
}

.strength {
  font-size: 13px;
  color: #271e25;
  position: relative;
  top: 5px;
  left: 0;
  transition: 0.5s all ease;
}
.bar {
  /* width: 400px; */
  height: 5px;
  background: red;
  transition: 0.5s all ease;
  max-width: 420px;
  margin: 2px 0 5px 5px;
  position: absolute;
}
</style>
